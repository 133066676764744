import apiEndpoints from '@/services/apiEndpoints';
import http from '@/services/http';

export default {
  state: () => {},
  mutations: {},
  getters: {},
  actions: {
    async getListOrder (_, payload) {
      const orderResponse = await http.get(apiEndpoints.getListOrder, payload);
      if (orderResponse.status && orderResponse.data) return orderResponse.data;
      return [];
    },
    async getOrder (_, id) {
      const orderResponse = await http.get(apiEndpoints.getOrderById.replace('{id}', id), {});
      if (orderResponse.status && orderResponse.data) return orderResponse.data;
      return false;
    },
    async updateOrder (_, payload) {
      const id = payload.id;
      delete payload.id;
      payload.order_items.forEach(item => {
        delete item.product;
        delete item.payload;
      });
      const orderResponse = await http.put(apiEndpoints.updateOrder.replace('{id}', id), payload);
      if (orderResponse.status) return orderResponse;
      return false;
    },
    async exportOrder (_, payload) {
      const orderResponse = await http.downloadPost(apiEndpoints.exportOrder, payload);
      return orderResponse;
    },
    async updateStatus (_, payload) {
      const orderResponse = await http.patch(apiEndpoints.updateStatus, payload);
      if (orderResponse.status) return orderResponse;
      return false;
    },
    async sendMail (_, payload) {
      const orderResponse = await http.post(apiEndpoints.sendMail, payload);
      if (orderResponse.status) return orderResponse;
      return false;
    },
    async getListHistoryOrder (_, payload) {
      const id = payload.order_id;
      delete payload.order_id;
      const orderResponse = await http.get(apiEndpoints.getListHistoryOrder.replace('{id}', id), payload);
      if (orderResponse.status && orderResponse.data) return orderResponse.data;
      return [];
    },
  },
};
